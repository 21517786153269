import { routes } from "@prmf/site-router";
import { Link } from "@remix-run/react";
import BgImage from "./BgImage";
import { clsx } from "clsx";

export default function QuickLinks() {
  const quickLinks = [
    {
      label: "Recent projects",
      link: routes.newsCaseStudies,
      icon: "/media/assets/quick-links-projects.svg",
    },
    {
      label: "Our products",
      link: routes.kitchens,
      icon: "/media/assets/quick-links-products.svg",
    },
    {
      label: "Visualiser",
      link: routes.visualiser,
      icon: "/media/assets/quick-links-visualiser.svg",
    },
    {
      label: "Why us?",
      link: routes.corporateResponsibility,
      icon: "/media/assets/quick-links-about.svg",
    },
    {
      label: "Get in touch",
      link: routes.contact,
      icon: "/media/assets/quick-links-contact.svg",
    },
  ];
  return (
    <section className="container space-y-6 py-10 text-center">
      <h2 className="text-3xl font-bold">Short on time?</h2>
      <p className="font-light">
        Here are some quick links to the most popular areas of our website.
      </p>
      <ul className="flex flex-wrap justify-center gap-20 py-10">
        {quickLinks.map((l) => (
          <li key={l.label}>
            <Link to={l.link} className="space-y-4">
              <BgImage
                src={l.icon}
                className={clsx(
                  "mx-auto aspect-square",
                  "rounded-full border",
                  "!w-28 lg:!w-40",
                  "bg-white !bg-[length:60%]",
                )}
              />
              <h3 className="font-bold">{l.label}</h3>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
}
